import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
const logoWidth = 120
export const discountCodePartners = [
  // ------GOJEK------
  {
    partnerName: 'gojek',
    link: 'https://www.gojek.com/vn/gofood/',
    logo: (
      <StaticImage
        src="../assets/images/partners/gojek-logo.png"
        alt="GoJek"
        width={logoWidth}
      ></StaticImage>
    ),
    discountCodes: [
      {
        code: 'Cart-off 65K',
        content: 'Giảm 15K đơn tối thiểu 65K',
      },
      {
        code: 'Cart-off 80k',
        content: 'Giảm 18K đơn tối thiểu 80K',
      },
      {
        code: 'Cart-off 100k',
        content: 'Giảm 24K đơn tối thiểu 100K',
      },
      {
        code: 'Cart-off 120k',
        content: 'Giảm 32K đơn tối thiểu 120K',
      },
      {
        code: 'Cart-off 200k',
        content: 'Giảm 60K đơn tối thiểu 200K',
      },
    ],
  },
  // ------NOW------
  {
    partnerName: 'now',
    link: 'https://shopeefood.vn/thuong-hieu/savor-banh-mi-tra-sua',
    logo: (
      <StaticImage
        src="../assets/images/partners/shopeefood-transparent.png"
        alt="ShoppeeFood"
        width={logoWidth}
      ></StaticImage>
    ),
    discountCodes: [
      {
        code: 'SIEUTIEC10',
        content: 'Giảm 10K cho đơn từ 20K',
        timeframe: '',
      },
      {
        code: 'SIEUTIEC20',
        content: 'Giảm 20k, giá trị đơn tối thiểu 50k',
        timeframe: '',
      },
      {
        code: 'SIEUTIEC40',
        content: 'Giảm 40K, giá trị đơn tối thiểu 100K',
      },
      {
        code: 'SIEUTIEC50',
        content: 'Giảm 50K cho đơn từ 140K',
      },
      {
        code: 'SIEUTIEC70',
        content: 'Giảm 70K cho đơn từ 200K',
      },
      {
        code: 'SIEUTIEC99',
        content: 'Giảm 99K, giá trị đơn tối thiểu 250K',
      },
    ],
  },
  // ------GRAB------
  {
    partnerName: 'grab',
    link: 'https://food.grab.com/vn/en/restaurants?lng=en&search=savor',
    logo: (
      <StaticImage
        src="../assets/images/partners/grabfood.png"
        alt="GrabFood"
        width={logoWidth}
      ></StaticImage>
    ),
    discountCodes: [
      {
        code: 'BOSS20',
        content: 'Giảm 20k đơn từ 40k',
        timeframe: '',
      },
      {
        code: 'BOSS30B',
        content: 'Giảm 30k đơn từ 80k',
        timeframe: '',
      },
      {
        code: 'BOSS40',
        content: 'Giảm 40k đơn từ 100k',
        timeframe: '',
      },
      {
        code: 'FREESHIP',
        content: 'Giảm 15k phí ship',
        timeframe: '',
      },
      {
        code: 'BOSS70',
        content: 'Giảm 70K đơn tối thiểu 200k',
        timeframe: '',
      },
      {
        code: 'BOSS50',
        content: 'Giảm 50k đơn từ 150k',
        timeframe: '',
      },
    ],
  },

  // ------BAEMIN------
  {
    partnerName: 'baemin',
    link: 'https://baemin.vn/',
    logo: (
      <StaticImage
        src="../assets/images/partners/baemin-logo.png"
        alt="BAEMIN"
        width={logoWidth}
      ></StaticImage>
    ),
    discountCodes: [
      {
        code: 'HND15',
        content: 'Giảm 23k cho đơn hàng từ 95k',
      },
      {
        code: 'HND17',
        content: 'Giảm 27k cho đơn hàng từ 120k',
      },
      {
        code: 'HND27',
        content: 'Giảm 27k cho đơn hàng từ 150k',
      },
    ],
  },
]
