import React from 'react'
import Layout from '../components/Layouts/default'
import { Container, Segment, Grid, Header, Divider } from 'semantic-ui-react'
import { discountCodePartners } from '../utils/discountCodes.js'
import styled from 'styled-components'

const StyledSegment = styled(Segment)({
  marginTop: '0!important',
  paddingTop: '0!important',
})

export default function discountCodesPage() {
  return (
    <Layout>
      <Container>
        <Divider />
        <Header as="h1" textAlign="center">
          Danh sách mã giảm giá Savor
        </Header>
        {discountCodePartners.map((partner, index) => (
          <StyledSegment key={index} style={{ marginTop: '0' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <a
                href={partner.link}
                target="_blank"
                rel="noreferrer noopener"
                alt={partner.partnerName.toUpperCase()}
                key={partner.name}
              >
                {partner.logo}
              </a>
            </div>

            <Grid columns={2}>
              {partner.discountCodes.map((discountCode, index) => (
                <Grid.Column key={index}>
                  <Segment>
                    <Header as="h3">{discountCode.code}</Header>
                    <p>{discountCode.content}</p>
                    <p>Thời hạn: {discountCode.timeframe}</p>
                  </Segment>
                </Grid.Column>
              ))}
            </Grid>
          </StyledSegment>
        ))}
      </Container>
    </Layout>
  )
}
